<template>
  <div class="afternoon">
    <h2 class="subtitle">
      Afternoon Sessions
    </h2>
    <div class="cards__container">
      <div class="cards__single grayblue presenter">
        <div class="cards__info">
          <p class="cards__title">
            Silver X Mining
          </p>
          <div class="cards__description">
            <p class="time">1:30 PM - 2:00 PM</p>
            <p>Patio del Fauno</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMobile" class="mobile slider">
      <carousel>
        <slide :key="1">
          <div class="grid__tracks">
            <div class="--title">
              Track 1 (Fauno West)
            </div>
            <div class="--slot t1" v-for="info in track1" :key="info.company">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="2">
          <div class="grid__tracks">
            <div class="--title">
              Track 2 (Fauno East)
            </div>
            <div class="--slot t2" v-for="info in track2" :key="info.company">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="3">
          <div class="grid__tracks">
            <div class="--title">
              Track 3 (Salon Azul)
            </div>
            <div class="--slot t3" v-for="info in track3" :key="info.company">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
    <div v-else>
      <div class="grid__container">
        <div class="grid__tracks">
          <div class="--title">
            Track 1 (Fauno West)
          </div>
          <div class="--slot t1" v-for="info in track1" :key="info.company">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 2 (Fauno East)
          </div>
          <div class="--slot t2" v-for="info in track2" :key="info.company">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 3 (Salon Azul)
          </div>
          <div class="--slot t3" v-for="info in track3" :key="info.company">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="cards__container">
      <div class="cards__single cerulean clickable" @click="openModal('jonopts')">
        <div class="speaker-img najarian"></div>
        <div class="circle"></div>
        <div class="cards__speaker">
          <p class="cards__name">
            Options Essentials in 60 Minutes with Jon “DRJ” Najarian
          </p>
          <p class="cards__spktitle">
            Market Rebellion - Co-Founder
          </p>
        </div>
        <div class="cards__description">
          <p class="time">2:00 PM - 3:00 PM</p>
          <p class="location">Patio del Fauno</p>
        </div>
      </div>
      <br class="linebrk">
      <div class="cards__single grayblue presenter">
        <div class="cards__info">
          <p class="cards__title">
            Ideanomics
          </p>
          <div class="cards__description">
            <p class="time">3:30 PM - 4:00 PM</p>
            <p>Patio del Fauno</p>
          </div>
        </div>
      </div>
      <br class="linebrk">
      <div class="cards__single cerulean">
        <div class="speaker-img pham"></div>
        <div class="circle"></div>
        <div class="cards__speaker">
          <p class="cards__name">
            Peter Pham
          </p>
          <p class="cards__spktitle">
            Science Studio Ventures - Co-Founder <br>Liquid Death, Dollar Shave Club
          </p>
        </div>
        <div class="cards__description">
          <p class="time">4:00 PM - 4:30 PM</p>
          <p class="location">Patio del Fauno</p>
        </div>
      </div>
      <div class="cards__single cerulean">
        <div class="speaker-img pierce"></div>
        <div class="circle"></div>
        <div class="cards__speaker">
          <p class="cards__name">
            Brock Pierce
          </p>
          <p class="cards__spktitle">
            Chairman of the Bitcoin Foundation, Presidential Candidate
          </p>
        </div>
        <div class="cards__description">
          <p class="time">4:30 PM - 5:30 PM</p>
          <p class="location">Patio del Fauno</p>
        </div>
      </div>
      <div class="cards__single green">
        <p class="cards__title">
          Happy Hour
          <br>
          @ Tacos & Tequila (Hotel)
        </p>
        <div class="cards__description">
          <p>5:30 PM - 7:00 PM</p>
        </div>
      </div>
      <div class="cards__single green">
        <p class="cards__title">
          The Money Ball by The Money Channel<br>@ <a href="https://maps.app.goo.gl/nRKnhb7zfRuFN9o4A" target="_blank">Monastery</a> - 250 Calle del Cristo, San Juan, 00901, Puerto Rico
        </p>
        <div class="cards__description">
          <p>7:00 PM - 11:00 PM</p>
        </div>
      </div>
    </div>
  </div>
  <div :class="{'modal-show': isModalOpen}" v-if="isModalOpen" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <h1 class="speaker">{{ activeCardInfo?.speaker }}</h1>
      <h2 class="title">{{ activeCardInfo?.title }}</h2>
      <p class="description" v-html="activeCardInfo?.description"></p>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  name: 'Day Two Afternoon',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      track1: [
        { start: '1:30 PM', end: '2:00 PM', company: 'OxbridgeRe', hr: '13:30', duration: 29 },
        { start: '2:00 PM', end: '2:30 PM', company: 'Catheter Precision', hr: '14:00', duration: 29 },
        { start: '2:30 PM', end: '3:00 PM', company: 'Oncocyte', hr: '14:30', duration: 29 },
        { start: '3:00 PM', end: '3:30 PM', company: 'Cibus Inc', hr: '15:00', duration: 29 },
        { start: '3:30 PM', end: '4:00 PM', company: 'Adamas One Corp', hr: '15:30', duration: 29 }
      ],
      track2: [
        { start: '1:30 PM', end: '2:00 PM', company: 'BacTech Environmenal Corporation', hr: '13:30', duration: 29 },
        { start: '2:00 PM', end: '2:30 PM', company: 'Smith Micro Software', hr: '14:00', duration: 29 },
        { start: '2:30 PM', end: '3:00 PM', company: 'UniGen Power', hr: '14:30', duration: 29 },
        { start: '3:00 PM', end: '3:30 PM', company: 'ProMIS Neurosciences', hr: '15:00', duration: 29 },
        { start: '3:30 PM', end: '4:00 PM', company: 'Skydweller', hr: '15:30', duration: 29 }
      ],
      track3: [
        { start: '1:30 PM', end: '2:00 PM', company: 'TC Biopharm Limited', hr: '13:30', duration: 29 },
        { start: '2:00 PM', end: '2:30 PM', company: 'Emmis Capital II', hr: '14:00', duration: 29 },
        { start: '2:30 PM', end: '3:00 PM', company: 'EV Mobility', hr: '14:30', duration: 29 },
        { start: '3:00 PM', end: '3:30 PM', company: 'Scienjoy', hr: '15:00', duration: 29 },
        { start: '3:30 PM', end: '4:00 PM', company: 'NAYA Biosciences', hr: '15:30', duration: 29 }
      ],
      cardData: {
        jonopts: {
          speaker: 'Jon Najarian',
          title: '"Options Essentials in 60 Minutes with Jon “DRJ” Najarian"',
          description: 'Jon Najarian, a trader with 43 years experience trading stock, options and futures will break down in easy to understand pieces the factors that go into pricing options and when to best use strategies to maximize your profits.'
        },
      },
      activeCardInfo: null,
      isModalOpen: false,
      isMobile: false,
      wogerHour: 16,
      wogerMins: 30,
      pierceHour: 16.5,
      happyHour: 17.5,
      eventDay: 2
    }
  },
  mounted() {
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 768;
    },
    openModal(cardId) {
      console.log()
      this.activeCardInfo = this.cardData[cardId];
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.activeCardInfo = null;
    },
    isHighlight(info) {
      const now = new Date();
      const actualHr = now.getHours() * 60 + now.getMinutes();
      const hr = parseInt(info.hr.split(":")[0]) * 60 + parseInt(info.hr.split(":")[1]);
      return hr <= actualHr && hr + info.duration >= actualHr;
    },
  },
  computed: {
    current() {
      return new Date();
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
