<template>
  <div class="lunch">
    <h2 class="subtitle">
      Lunch
    </h2>
    <div class="cards__container">
      <div class="cards__single grayblue">
        <p class="cards__title">
          Lunch <br> @ Patio del Fauno Lobby
        </p>
        <div class="cards__description">
          <p>12:00 PM - 12:30 PM</p>
        </div>
      </div>
      <br class="linebrk">
      <div class="cards__single cerulean noimg noheight">
        <div class="cards__speaker">
          <p class="cards__name">
            Panel - Navigating the Evolving Landscape of Capital Markets
          </p>
          <p class="cards__spktitle">
            Moderator, Chris Miglino<br>
            Panelist - Richard Friedman, Sheppard Mullin<br>
            Panelist - Peter Goldstein, Emmis Capital II
          </p>
        </div>
        <div class="cards__description">
          <p class="time">12:00 PM - 12:30 PM</p>
          <p class="location">Patio del Fauno</p>
        </div>
      </div>
      <div class="cards__single cerulean clickable" @click="openModal('bryn')">
        <div class="speaker-img bryn"></div>
        <div class="circle"></div>
        <div class="cards__speaker">
          <p class="cards__name">
            Bryn Talkington
          </p>
          <p class="cards__spktitle">
            Requisite Capital Management - Managing Partner & Co-Founder
          </p>
        </div>
        <div class="cards__description">
          <p class="time">12:30 PM - 1:00 PM</p>
          <p class="location">Patio del Fauno</p>
        </div>
      </div>
      <div class="cards__single cerulean clickable" @click="openModal('brian')">
        <div class="speaker-img bourgerie"></div>
        <div class="circle"></div>
        <div class="cards__speaker">
          <p class="cards__name">
            Brian Bourgerie
          </p>
          <p class="cards__spktitle">
            Invest Puerto Rico - Innovation, Investment & Entrepreneurship
          </p>
        </div>
        <div class="cards__description">
          <p class="time">1:00 PM - 1:30 PM</p>
          <p class="location">Patio del Fauno</p>
        </div>
      </div>
    </div>
  </div>
  <div :class="{'modal-show': isModalOpen}" v-if="isModalOpen" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <h1 class="speaker">{{ activeCardInfo?.speaker }}</h1>
      <h2 class="title">{{ activeCardInfo?.title }}</h2>
      <p class="description" v-html="activeCardInfo?.description"></p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Day Three Lunch',
  data() {
    return {
      lunchHour: 12,
      lunchMins: 0,
      loPrestiHour: 12.5,
      eventDay: 3,
      cardData: {
        bryn: {
          speaker: 'Bryn Talkington',
          title: '"2024 Investment Themes and Stock Market Realities"',
          description: 'In my upcoming speech at the investment conference, I will delve into the pivotal investment themes of 2024, offering a realistic perspective on stock performance in the current economic climate.<br><br> In addition, part of the discussion will be the active vs. passive investment debate. I\'ll share key statistics to highlight this pattern and its implications for those seeking optimized returns.<br><br>Additionally, I will reveal some lesser known, yet insightful statistics about the stock market and the impact on portfolios. These will shed light on market dynamics and investor behavior, providing valuable insights for both experienced and new investors.<br><br>The session is designed to provide a comprehensive understanding of the investment landscape in 2024, helping attendees make informed decisions in a complex market. Join me to explore these critical topics, essential for investment success in 2024.'
        },
        brian: {
          speaker: 'Brian Bourgerie',
          title: '"Deep Dive into the Tax and IP Benefits of Puerto Rico"',
          description: 'Take a step deeper into example structures available to companies setting up operations on the island. From tax nuances to unique IP structures, you’ll come away with a better understanding of what is possible for those looking to optimize their operations on the island.'
        },
      },
      activeCardInfo: null,
      isModalOpen: false
    }
  },
  methods: {
    openModal(cardId) {
      console.log("Opening modal for card:", cardId);
      this.activeCardInfo = this.cardData[cardId];
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.activeCardInfo = null;
    }
  },
  computed: {
    current() {
      return new Date();
    }
  },
};
</script>