<template>
  <main id="home">
    <dayone />
    <daytwo />
    <daythree />
  </main>
</template>

<style lang="scss" scoped>
  @import '../styles/pages/home.scss';
</style>

<script>
// @ is an alias to /src
import dayone from '@/components/day-one.vue';
import daytwo from '@/components/day-two.vue';
import daythree from '@/components/day-three.vue';

export default {
  name: 'Home',
  data() {
    return {
    };
  },
  components: {
    dayone,
    daytwo,
    daythree
  },
  methods: {
  },
  mounted() {
  },
};
</script>
