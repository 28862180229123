<template>
  <section class="agenda monday">
    <div class="agenda__content container">
      <div class="agenda__date">
        <div class="date__container">
          <div>
            <h1 class="date__title">
              Tuesday, January 23rd
            </h1>
            <p class="date__description">
              Pre-Registration
            </p>
          </div>
          <button class="rotate-btn" v-bind:class="{ 'is-rotated': isRotated, 'iphone-btn': isIphone }"
          @click="seccionVisible = !seccionVisible" v-on:click="toggleRotation">
            <svg :class="{ 'iphone': isIphone }" width="14px" height="9px" viewBox="0 0 18 11">
              <path fill="#3AADCC" d="M9.1,7.8l6.6-6.6C15.8,1.1,16,1,16.1,0.9c0.2,0,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.3,0.2,0.4,0.3
              s0.2,0.3,0.3,0.4C18,1.8,18,2,18,2.2s0,0.4-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4l-7.5,7.5C9.8,10.8,9.5,11,9.2,11
              c-0.4,0-0.7-0.1-0.9-0.4L0.8,3.1C0.7,3,0.6,2.8,0.5,2.7C0.3,2.5,0.2,2.4,0.2,2.2c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4
              S0.9,1.1,1,1c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0,0.5,0.1C2.3,1,2.4,1.1,2.5,1.2L9.1,7.8z"/>
            </svg>
          </button>
        </div>
        <hr class="date__line">
      </div>
      <transition name="fade">
        <div v-show="seccionVisible">
          <div class="cards__container">
            <div class="cards__single gray noheight">
              <img src="@/assets/icons/registration.png" class="cards__icon cards__mobile" alt="Icon">
              <p class="cards__title cards__desktop">
                Pre - Registration <br> @ Patio del Fauno
              </p>
              <div class="cards__description">
                <h3 class="cards__title cards__mobile">
                  Pre - Registration <br> @ Patio del Fauno
                </h3>
                <p>4:00 PM - 7:00 PM</p>
                <img src="@/assets/icons/registration.png" class="cards__icon cards__desktop" alt="Icon">
              </div>
            </div>
            <div class="cards__single green">
              <p class="cards__title">
                Welcome Happy Hour
                <br>
                @ West Veranda, Condado Vanderbilt
              </p>
              <div class="cards__description">
                <p>5:00 PM - 7:00 PM</p>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<style lang="scss">
  @import '@/styles/components/dayone.scss';
</style>

<script>

export default {
  name: 'Day One',
  data() {
    return {
      seccionVisible: true,
      isRotated: true,
      isIphone: false
    }
  },
  methods: {
    toggleRotation() {
      this.isRotated = !this.isRotated;
    },
  },
  mounted() {
    this.isIphone = /iPhone/.test(navigator.userAgent);
  }
};
</script>
