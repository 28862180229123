<template>
  <div class="cards__container">
    <div class="cards__single gray">
      <img src="@/assets/icons/registration.png" class="cards__icon cards__mobile" alt="Registration Icon">
      <p class="cards__title cards__desktop">
        Registration <br> @ Patio del Fauno Lobby
      </p>
      <div class="cards__description">
        <h3 class="cards__title cards__mobile">
          Registration <br> @ Patio del Fauno Lobby
        </h3>
        <p>7:00 AM - 4:00 PM</p>
        <img src="@/assets/icons/registration.png" class="cards__icon cards__desktop" alt="Registration Icon">
      </div>
    </div>
    <div class="cards__single grayblue">
      <p class="cards__title">
        Breakfast <br> @ Patio del Fauno Lobby
      </p>
      <div class="cards__description">
        <p>8:00 AM - 9:00 AM</p>
      </div>
    </div>
    <div class="cards__single cerulean clickable" @click="openModal('jon')">
      <div class="speaker-img najarian"></div>
      <div class="circle"></div>
      <div class="cards__speaker">
        <p class="cards__name">
          Jon "DRJ" Najarian
        </p>
        <p class="cards__spktitle">
          Market Rebellion - Co-Founder
        </p>
      </div>
      <div class="cards__description">
        <p class="time">9:00 AM - 9:30 AM</p>
        <p class="location">Patio del Fauno</p>
      </div>
    </div>
    <div class="cards__single cerulean clickable" @click="openModal('thomas')">
      <div class="speaker-img thomas"></div>
      <div class="circle"></div>
      <div class="cards__speaker">
        <p class="cards__name">
          Thomas J. Hayes
        </p>
        <p class="cards__spktitle">
          Great Hill Capital - Founder, Chairman & Managing Member
        </p>
      </div>
      <div class="cards__description">
        <p class="time">9:30 AM - 10:00 AM</p>
        <p class="location">Patio del Fauno</p>
      </div>
    </div>
    <a href="https://www.meetmax.com/sched/event_103091/conference_home.html" class="meetmax" target="_blank">
      <div class="cards__single purple">
        <img src="@/assets/icons/meets.png" class="cards__icon cards__mobile" alt="Meetings Icon">
        <p class="cards__title cards__desktop">
          1:1 Meetings -
          <a href="https://www.meetmax.com/sched/event_103091/conference_home.html" target="_blank">Login</a>
          <br>@ Salon Dorado
        </p>
        <div class="cards__description">
          <h3 class="cards__title cards__mobile">
            1:1 Meetings <br>@ Salon Dorado
          </h3>
          <p>10:00 AM - 4:00 PM</p>
          <img src="@/assets/icons/meets.png" class="cards__icon cards__desktop" alt="Meetings Icon">
        </div>
      </div>
    </a>
  </div>
  <div class="morning">
    <h2 class="subtitle">
      Morning Sessions
    </h2>
    <div v-if="isMobile" class="mobile slider">
      <carousel>
        <slide :key="1">
          <div class="grid__tracks">
            <div class="--title">
              Track 1 (Fauno West)
            </div>
            <div class="--slot t1" v-for="info in track1" :key="info.company">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="2">
          <div class="grid__tracks">
            <div class="--title">
              Track 2 (Fauno East)
            </div>
            <div class="--slot t2" v-for="info in track2" :key="info.company">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="3">
          <div class="grid__tracks">
            <div class="--title">
              Track 3 (Salon Azul)
            </div>
            <div class="--slot t3" v-for="info in track3" :key="info.company">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
    <div v-else>
      <div class="grid__container">
        <div class="grid__tracks">
          <div class="--title">
            Track 1 (Fauno West)
          </div>
          <div class="--slot t1" v-for="info in track1" :key="info.company">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 2 (Fauno East)
          </div>
          <div class="--slot t2" v-for="info in track2" :key="info.company">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 3 (Salon Azul)
          </div>
          <div class="--slot t3" v-for="info in track3" :key="info.company">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="cards__container">
      <div class="cards__single grayblue presenter">
        <div class="cards__info">
          <p class="cards__title">
            Perspective Therapeutics
          </p>
          <div class="cards__description">
            <p class="time">11:30 AM - 12:00 PM</p>
            <p>Patio del Fauno</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div :class="{'modal-show': isModalOpen}" v-if="isModalOpen" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <h1 class="speaker">{{ activeCardInfo?.speaker }}</h1>
      <h2 class="title">{{ activeCardInfo?.title }}</h2>
      <p class="description" v-html="activeCardInfo?.description"></p>
    </div>
  </div>
</template>

<script>

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  name: 'Day Two Morning',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      track1: [
        { start: '10:00 AM', end: '10:30 AM', company: 'Vox Royalty', hr: '10:00', duration: 29 },
        { start: '10:30 AM', end: '11:00 AM', company: 'GoHealth', hr: '10:30', duration: 29 },
        { start: '11:00 AM', end: '11:30 AM', company: 'CYNGN', hr: '11:00', duration: 29 },
        { start: '11:30 AM', end: '12:00 PM', company: 'Lightwave Logic', hr: '11:30', duration: 29 }
      ],
      track2: [
        { start: '10:00 AM', end: '10:30 AM', company: 'Lisata Therapeutics', hr: '10:00', duration: 29 },
        { start: '10:30 AM', end: '11:00 AM', company: 'WiSa Technologies', hr: '10:30', duration: 29 },
        { start: '11:00 AM', end: '11:30 AM', company: 'Heritage Global', hr: '11:00', duration: 29 },
        { start: '11:30 AM', end: '12:00 PM', company: 'Solowin Holdings', hr: '11:30', duration: 29 }
      ],
      track3: [
        { start: '10:00 AM', end: '10:30 AM', company: 'RYVYL', hr: '10:00', duration: 29 },
        { start: '10:30 AM', end: '11:00 AM', company: 'First Wave BioPharma, Inc.', hr: '10:30', duration: 29 },
        { start: '11:00 AM', end: '11:30 AM', company: 'IPA Therapeutics', hr: '11:00', duration: 29 },
        { start: '11:30 AM', end: '12:00 PM', company: 'Jackpot Digital Inc.', hr: '11:30', duration: 29 }
      ],
      cardData: {
        thomas: {
          speaker: 'Thomas Hayes',
          title: '“Turnaround Tom\'s” Top Picks\' and Outlook for 2024',
          description: 'Thomas J. Hayes is the Founder, Chairman and Managing Member of Great Hill Capital, LLC (a long/short equity manager based in New York City). He has been Featured OnFox Business TV, CNBC, BBC, Yahoo! Finance TV, Wall Street Journal, Barron\'s, Bloomberg, CNBC Asia, New York Post, Fortune, Cheddar TV, i24News, CGTN, Financial Times, Reuters, MarketWatch, U.S. News & World Report, Kiplinger, TheStreet, CGTN America, CNA Asia, ZeroHedge, Inside Futures, BarChart, Seeking Alpha, Fidelity and other venues. <br><br> He is the founder of top ranked podcast (in Hedge Fund category by feedspot) "Hedge Fund Tips with Tom Hayes." It can be found on YouTube or wherever you get your podcasts.'
        },
        jon: {
          speaker: 'Jon Najarian',
          title: '"Do you know how stocks do in an election year?"',
          description: 'Jon Najarian breaks down the stock market performances in past presidential years and suggests the best performing stocks to own or to ditch depending on which candidate is leading in the polls.'
        },
      },
      activeCardInfo: null,
      isModalOpen: false,
      isMobile: false,
    }
  },
  mounted() {
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 768;
    },
    openModal(cardId) {
      console.log()
      this.activeCardInfo = this.cardData[cardId];
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.activeCardInfo = null;
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
