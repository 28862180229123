<template>
  <div class="lunch">
    <h2 class="subtitle">
      Lunch
    </h2>
    <div class="cards__container">
      <div class="cards__single grayblue">
        <p class="cards__title">
          Lunch <br> @ Patio del Fauno Lobby
        </p>
        <div class="cards__description">
          <p>12:00 PM - 12:30 PM</p>
        </div>
      </div>
      <br class="linebrk">
      <div class="cards__single cerulean clickable" @click="openModal('ramy')">
        <div class="speaker-img ramy"></div>
        <div class="circle"></div>
        <div class="cards__speaker">
          <p class="cards__name">
            Can You Really Think and Grow Rich?
          </p>
          <p class="cards__spktitle">
            Ramy El-Batrawi
          </p>
        </div>
        <div class="cards__description">
          <p class="time">12:00 PM - 12:30 PM</p>
          <p class="location">Patio del Fauno</p>
        </div>
      </div>
      <div class="cards__single cerulean clickable" @click="openModal('peter')">
        <div class="speaker-img schiff"></div>
        <div class="circle"></div>
        <div class="cards__speaker">
          <p class="cards__name">
            Peter Schiff
          </p>
          <p class="cards__spktitle">
            Euro Pacific Asset Management - Chief Economist & Global Strategist
          </p>
        </div>
        <div class="cards__description">
          <p class="time">12:30 PM - 1:00 PM</p>
          <p class="location">Patio del Fauno</p>
        </div>
      </div>
      <div class="cards__single cerulean clickable" @click="openModal('zach')">
        <div class="speaker-img karabell"></div>
        <div class="circle"></div>
        <div class="cards__speaker">
          <p class="cards__name">
            Zachary Karabell
          </p>
          <p class="cards__spktitle">
            Progress Network at New America - Founder, River Twice Capital - President
          </p>
        </div>
        <div class="cards__description">
          <p class="time">1:00 PM - 1:30 PM</p>
          <p class="location">Patio del Fauno</p>
        </div>
      </div>
    </div>
  </div>
  <div :class="{'modal-show': isModalOpen}" v-if="isModalOpen" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <h1 class="speaker">{{ activeCardInfo?.speaker }}</h1>
      <h2 class="title">{{ activeCardInfo?.title }}</h2>
      <p class="description" v-html="activeCardInfo?.description"></p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Day Two Lunch',
  data() {
    return {
      cardData: {
        peter: {
          speaker: 'Peter Schiff',
          title: '"End came for the Fed. Inflation will heat up just as the economy cools down"',
          description: 'Investors still don\'t understand the problem. Over the past two decades the Fed has solved every problem by creating more inflation. Quotiative Easing is merely a euphonism for inflation. But now inflation has become the problem. It\'s been a problem all along, it\'s just that few noticed or cared, as consumer price increases were still small enough to be hidden behind a flawed CPI and inflation pushed asset prices higher, making owners of those assets richer.  But now that inflation has moved from the stock market to the super market, it\'s making average Americans much poorer. So far we\'ve only seen the tip of an inflation iceberg. There is no politically viable way for the Fed to put the inflation genie back in the bottle. Investors need to prepare for 1970s stagflation on steroids.'
        },
        zach: {
          speaker: 'Zach Karabell',
          title: '“2024: year of our dreams or year of our nightmares”',
          description: 'In framing the year ahead, Zachary Karabell will range widely from markets to politics to business and offer a guide to where we are heading. No one has a crystal ball, and too many people claim to know the future; but some trends are in place that are powerfully shaping out world - from the deflationary effects of technology to a globalization that to borrow from Monty Python isn\'t dead yet - and those show no signs of slowing down or changing course.'
        },
        ramy: {
          speaker: 'Ramy El-Batrawi',
          title: '"Can You Really Think and Grow Rich?"',
          description: 'Join us for an incredible keynote speech by Ramy El-Batrawi, an American businessman, entrepreneur, philanthropist, and author of "Can You Really Think and Grow Rich?" <br><br> Ramy is known for his expertise in structuring winning transactions and protecting investors. He has had business deals with influential individuals and faced challenges with prosecutors and unethical investors. Despite setbacks, he has consistently rebuilt himself stronger. Some of his notable achievements include acquiring a parent company of travel-rated organizations, negotiating the acquisition of assets and ongoing operations of a bankrupt company, and forming an investment group to secure a regional airline bid. He has also sourced and negotiated the purchase of a company and structured financing for the telecommunications industry. Ramy has demonstrated exceptional leadership as CEO, Director, and Chairman of the Board. He has partnered with renowned businessmen to facilitate significant transactions and has successfully navigated deals among large corporations. In recent years, he formed a successful holding company, launched an innovative platform providing electric vehicles as amenities, and produced a captivating musical review.<br><br> Don\'t miss out on this opportunity to hear Ramy El-Batrawi share his journey and the principles that led to his remarkable success. Join us on Wednesday the 24th at 12:00 for an inspiring and enlightening talk.'
        },
      },
      activeCardInfo: null,
      isModalOpen: false
    }
  },
  methods: {
    openModal(cardId) {
      console.log()
      this.activeCardInfo = this.cardData[cardId];
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.activeCardInfo = null;
    }
  },
};
</script>