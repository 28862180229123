<template>
  <div class="cards__container">
    <div class="cards__single gray">
      <img src="@/assets/icons/registration.png" class="cards__icon cards__mobile" alt="Registration Icon">
      <p class="cards__title cards__desktop">
        Registration <br> @ Patio del Fauno Lobby
      </p>
      <div class="cards__description">
        <h3 class="cards__title cards__mobile">
          Registration <br> @ Patio del Fauno Lobby
        </h3>
        <p>7:00 AM - 4:00 PM</p>
        <img src="@/assets/icons/registration.png" class="cards__icon cards__desktop" alt="Registration Icon">
      </div>
    </div>
    <div class="cards__single grayblue"
    :class="{ 'speaker-highlight': current.getHours() >= breakfastHour && current.getHours() < breakfastHour + 1 && current.getDay() === eventDay }">
      <p class="cards__title">
        Breakfast <br> @ Patio del Fauno Lobby
      </p>
      <div class="cards__description">
        <p>8:00 AM - 9:00 AM</p>
      </div>
    </div>
    <div class="cards__single cerulean clickable" @click="openModal('ella')"
    :class="{ 'speaker-highlight': current.getHours() >= sosnoffHour && current.getHours() < sosnoffHour + 1 && current.getDay() === eventDay }">
      <div class="speaker-img ella"></div>
      <div class="circle"></div>
      <div class="cards__speaker">
        <p class="cards__name">
          Ella Woger Nieves
        </p>
        <p class="cards__spktitle">
          Invest Puerto Rico - Chief Executive Officer
        </p>
      </div>
      <div class="cards__description">
        <p class="time">9:00 AM - 9:30 AM</p>
        <p class="location">Patio del Fauno</p>
      </div>
    </div>
    <div class="cards__single cerulean"
    :class="{ 'speaker-highlight': current.getHours() >= sosnoffHour && current.getHours() < sosnoffHour + 1 && current.getDay() === eventDay }">
      <div class="speaker-img marc"></div>
      <div class="circle"></div>
      <div class="cards__speaker">
        <p class="cards__name">
          Marc X. LoPresti
        </p>
        <p class="cards__spktitle">
          IDI Group - Founder & Managing Director
        </p>
      </div>
      <div class="cards__description">
        <p class="time">9:30 AM - 10:00 AM</p>
        <p class="location">Patio del Fauno</p>
      </div>
    </div>
    <div class="cards__single cerulean noimg noheight">
      <div class="cards__speaker">
        <p class="cards__name">
          Panel - Breaking Ground: Real Estate Development in Puerto Rico
        </p>
        <p class="cards__spktitle">
          Lukas Macniak, Oskar Kowalski & Karla Barrera-Morstad
        </p>
      </div>
      <div class="cards__description">
        <p class="time">11:00 AM - 11:30 AM</p>
        <p class="location">Patio del Fauno</p>
      </div>
    </div>
    <br class="linebrk">
    <a href="https://www.meetmax.com/sched/event_103091/conference_home.html" class="meetmax" target="_blank">
      <div class="cards__single purple">
        <img src="@/assets/icons/meets.png" class="cards__icon cards__mobile" alt="Meetings Icon">
        <p class="cards__title cards__desktop">
          1:1 Meetings -
          <a href="https://www.meetmax.com/sched/event_103091/conference_home.html" target="_blank">Login</a>
          <br>@ Salon Dorado
        </p>
        <div class="cards__description">
          <h3 class="cards__title cards__mobile">
            1:1 Meetings <br>@ Salon Dorado
          </h3>
          <p>10:00 AM - 4:00 PM</p>
          <img src="@/assets/icons/meets.png" class="cards__icon cards__desktop" alt="Meetings Icon">
        </div>
      </div>
    </a>
  </div>
  <div class="morning">
    <h2 class="subtitle">
      Morning Sessions
    </h2>
    <div v-if="isMobile" class="mobile slider">
      <carousel>
        <slide :key="1">
          <div class="grid__tracks">
            <div class="--title">
              Track 1 (Fauno West)
            </div>
            <div class="--slot t1" v-for="info in track1" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="2">
          <div class="grid__tracks">
            <div class="--title">
              Track 2 (Fauno East)
            </div>
            <div class="--slot t2" v-for="info in track2" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="3">
          <div class="grid__tracks">
            <div class="--title">
              Track 3 (Salon Azul)
            </div>
            <div class="--slot t3" v-for="info in track3" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
    <div v-else>
      <div class="grid__container">
        <div class="grid__tracks">
          <div class="--title">
            Track 1 (Fauno West)
          </div>
          <div class="--slot t1" v-for="info in track1" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 2 (Fauno East)
          </div>
          <div class="--slot t2" v-for="info in track2" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 3 (Salon Azul)
          </div>
          <div class="--slot t3" v-for="info in track3" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div :class="{'modal-show': isModalOpen}" v-if="isModalOpen" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <h1 class="speaker">{{ activeCardInfo?.speaker }}</h1>
      <h2 class="title">{{ activeCardInfo?.title }}</h2>
      <p class="description" v-html="activeCardInfo?.description"></p>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  name: 'Day Three Morning',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      track1: [
        { start: '10:00 AM', end: '10:30 AM', company: 'GEMXX', hr: '10:00', duration: 29 },
        { start: '10:30 AM', end: '11:00 AM', company: 'Athira Pharma', hr: '10:30', duration: 29 },
        { start: '11:00 AM', end: '11:30 AM', company: 'Soar.com', hr: '11:00', duration: 29 },
        { start: '11:30 AM', end: '12:00 PM', company: 'Reserved', hr: '11:30', duration: 29 }
      ],
      track2: [
        { start: '10:00 AM', end: '10:30 AM', company: 'Can-Fite BioPharma, Ltd.', hr: '10:00', duration: 29 },
        { start: '10:30 AM', end: '11:00 AM', company: 'Soligenix', hr: '10:30', duration: 29 },
        { start: '11:00 AM', end: '11:30 AM', company: 'Reserved', hr: '11:00', duration: 29 },
        { start: '11:30 AM', end: '12:00 PM', company: 'Energy Finders Inc', hr: '11:30', duration: 29 }
      ],
      track3: [
        { start: '10:00 AM', end: '10:30 AM', company: 'Envirotech Vehicles', hr: '10:00', duration: 29 },
        { start: '10:30 AM', end: '11:00 AM', company: 'GPO Plus', hr: '10:30', duration: 29 },
        { start: '11:00 AM', end: '11:30 AM', company: 'SeaNet', hr: '11:00', duration: 29 },
        { start: '11:30 AM', end: '12:00 PM', company: 'Two hands Corporation', hr: '11:30', duration: 29 }
      ],
      cardData: {
        ella: {
          speaker: 'Ella Woger',
          title: '"Puerto Rico: An emerging jurisdiction of choice"',
          description: 'Learn about why Puerto Rico has increasingly become a jurisdiction of interest to companies large and small as well as investors of every type. Understand the basics of what the island has to offer and the resources available to assess the opportunity.'
        },
      },
      activeCardInfo: null,
      isModalOpen: false,
      isMobile: false,
      breakfastHour: 8,
      najarianHour: 9,
      eventDay: 3
    }
  },
  mounted() {
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 768;
    },
    isHighlight(info) {
      const now = new Date();
      const actualHr = now.getHours() * 60 + now.getMinutes();
      const hr = parseInt(info.hr.split(":")[0]) * 60 + parseInt(info.hr.split(":")[1]);
      return hr <= actualHr && hr + info.duration >= actualHr;
    },
    openModal(cardId) {
      this.activeCardInfo = this.cardData[cardId];
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.activeCardInfo = null;
    }
  },
  computed: {
    current() {
      return new Date();
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
