<template>
    <div class="afternoon">
    <h2 class="subtitle">
      Afternoon Sessions
    </h2>
    <div v-if="isMobile" class="mobile slider">
      <carousel>
        <slide :key="1">
          <div class="grid__tracks">
            <div class="--title">
              Track 1 (Fauno West)
            </div>
            <div class="--slot t1" v-for="info in track1" :key="info.company">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p v-html="info.company" class="--slot__title">
              </p>
            </div>
          </div>
        </slide>
        <slide :key="2">
          <div class="grid__tracks">
            <div class="--title">
              Impeller Investment / Track 2
            </div>
            <div class="--slot t2" v-for="info in track2" :key="info.company">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="3">
          <div class="grid__tracks">
            <div class="--title">
              Track 3 (Salon Azul)
            </div>
            <div class="--slot t3" v-for="info in track3" :key="info.company">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
    <div v-else>
      <div class="grid__container">
        <div class="grid__tracks">
          <div class="--title">
            Track 1 (Fauno West)
          </div>
          <div class="--slot t1" v-for="info in track1" :key="info.company">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p v-html="info.company" class="--slot__title">
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Impeller Investment / Track 2
          </div>
          <div class="--slot t2" v-for="info in track2" :key="info.company">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 3 (Salon Azul)
          </div>
          <div class="--slot t3" v-for="info in track3" :key="info.company">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="cards__container">
      <div class="cards__single cerulean noheight">
        <div class="speaker-img divine"></div>
        <div class="circle"></div>
        <div class="cards__speaker">
          <p class="cards__name">
            Bitcoin Options: Risk Management & Statistical Arbitrage with John Divine
          </p>
          <p class="cards__spktitle">
            BlockFills, Digital Asset OTC Trading
          </p>
        </div>
        <div class="cards__description">
          <p class="time">1:30 PM - 2:00 PM</p>
          <p class="location">Patio del Fauno</p>
        </div>
      </div>
      <div class="cards__single cerulean">
        <div class="speaker-img najarian"></div>
        <div class="circle"></div>
        <div class="cards__speaker">
          <p class="cards__name">
            Selling Puts Like the Smart Money with Jon “DRJ” Najarian
          </p>
          <p class="cards__spktitle">
            Market Rebellion - Co-Founder
          </p>
        </div>
        <div class="cards__description">
          <p class="time">2:00 PM - 3:00 PM</p>
          <p class="location">Patio del Fauno</p>
        </div>
      </div>
      <div class="cards__single cerulean clickable" @click="openModal('igor')">
        <div class="speaker-img gonta"></div>
        <div class="circle"></div>
        <div class="cards__speaker">
          <p class="cards__name">
            Igor Gonta
          </p>
          <p class="cards__spktitle">
            Market Prophit, CEORWA.Holdings - CEO/Co-Founder
          </p>
        </div>
        <div class="cards__description">
          <p class="time">3:00 PM - 3:30 PM</p>
          <p class="location">Track 3 - Salon Azul</p>
        </div>
      </div>
      <div class="cards__single cerulean clickable" @click="openModal('terpin')">
        <div class="speaker-img terpin"></div>
        <div class="circle"></div>
        <div class="cards__speaker">
          <p class="cards__name">
            Michael Terpin
          </p>
          <p class="cards__spktitle">
            Transform Group, BitAngels - Founder and CEO
          </p>
        </div>
        <div class="cards__description">
          <p class="time">4:00 PM - 4:30 PM</p>
          <p class="location">Patio del Fauno</p>
        </div>
      </div>
      <div class="cards__single cerulean">
        <div class="speaker-img miglino"></div>
        <div class="circle"></div>
        <div class="cards__speaker">
          <p class="cards__name">
            Chris Miglino
          </p>
          <p class="cards__spktitle">
            SRAX - Founder and CEO
          </p>
        </div>
        <div class="cards__description">
          <p class="time">4:30 PM - 5:00 PM</p>
          <p class="location">Patio del Fauno</p>
        </div>
      </div>
      <br class="linebrk">
      <div class="cards__single green">
        <p class="cards__title">
          Closing Cocktail Party by Maxim Group and Parallel 18
          <br>
          @ West Veranda, Condado Vanderbilt
        </p>
        <div class="cards__description">
          <p>5:00 PM - 8:00 PM</p>
        </div>
      </div>
    </div>
  </div>
  <div :class="{'modal-show': isModalOpen}" v-if="isModalOpen" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <h1 class="speaker">{{ activeCardInfo?.speaker }}</h1>
      <h2 class="title">{{ activeCardInfo?.title }}</h2>
      <p class="description" v-html="activeCardInfo?.description"></p>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  name: 'Day Three Afternoon',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      track1: [
        { start: '1:30 PM', end: '1:50 PM', company: 'Coco Taps', hr: '13:30', duration: 29 },
        { start: '1:50 PM', end: '2:30 PM', company: 'Medici Bank / Lighting works', hr: '14:00', duration: 29 },
        { start: '2:30 PM', end: '2:50 PM', company: 'Persist Ventures', hr: '14:30', duration: 29 },
        { start: '2:50 PM', end: '3:40 PM', company: 'Jesus Coin / Made in America / EstateX', hr: '15:00', duration: 29 },
        { start: '3:40 PM', end: '4:00 PM', company: 'Flashy Cash', hr: '15:30', duration: 29 }
      ],
      track2: [
        { start: '1:30 PM', end: '2:00 PM', company: 'EDERRA', hr: '13:30', duration: 29 },
        { start: '2:00 PM', end: '2:30 PM', company: 'MBQ Pharma / Rubidex', hr: '14:00', duration: 29 },
        { start: '2:30 PM', end: '3:00 PM', company: 'CelerOps / Anisoptera', hr: '14:30', duration: 29 },
        { start: '3:00 PM', end: '3:30 PM', company: 'Abartys Health / RED Atlas', hr: '15:00', duration: 29 },
        { start: '3:30 PM', end: '4:00 PM', company: 'Cuerdas Verdes / Impeller Demo', hr: '15:30', duration: 29 }
      ],
      track3: [
        { start: '1:30 PM', end: '2:00 PM', company: 'Reserved', hr: '13:30', duration: 29 },
        { start: '2:00 PM', end: '3:00 PM', company: 'Reserved', hr: '14:00', duration: 59 },
        { start: '3:00 PM', end: '3:30 PM', company: 'Keynote Session: Igor Gonta', hr: '15:00', duration: 29 },
        { start: '3:30 PM', end: '4:00 PM', company: 'RRBitcoins', hr: '15:30', duration: 29 }
      ],
      cardData: {
        igor: {
          speaker: 'Igor Gonta',
          title: '"Real-World-Assets (RWA) Tokenization - The Next Crypto Frontier"',
          description: 'The financial markets are witnessing a transformational and seismic shift with the advent of blockchain technology and the tokenization of Real-World-Assets (RWA).  The size of the Tokenization Market is $475 Trillion. Tokenization is the process of converting rights to an asset or a company into a digital token on a blockchain.  This natural evolution of the financialization of “everything” is poised to disrupt traditional financial processes.  This talk will give an overview of the tokenization process, the benefits of tokenization of an asset on blockchain, and provide some examples of recent tokenization efforts by institutions.<br><br> Also, here is the description of my company to put up for investors to see so that they can book time slots with me to discuss our current institutional capital raise:<br><br> <b>RWA Holdings Corp</b> <br> RWA Holdings is an exchange for both crypto currency trading with unique, institutional-quality market intelligence tools and products as well as the first exchange providing access to the trading of any Tokenized Real-World-Asset. <br> Website: <a href="https://rwa.holdings/" target="_blank">RWA.holdings</a>'
        },
        terpin: {
          speaker: 'Michael Terpin',
          title: '"AI, Crypto and Public Equity: How the Three Markets Help Support One Another"',
          description: 'AI is the hottest sector in technology investing, with more than $75 billion invested since November 2022.  Crypto markets, despite a deep bear market in 2022, now stand at $1.5 trillion, roughly half the value of its all-time high from 2021, with credible predictions of the market for bitcoin alone passing $2 trillion by 2025.<br><br>Public markets are generally latecomers to technology trends, but then amplify the magnitude as retail investors clamor for a piece of the new pie (e.g., dot com mania in late 1990s, unicorn valuations in 2021).  How will AI, crypto and public market dynamics intersect in the coming 3-5 years?  AI and crypto investor and advisor Michael Terpin, who helped launch Ethereum and Tether in their early days and is now additionally invested in early AI startups, helps explain the trends and possibilities.'
        },
      },
      activeCardInfo: null,
      isModalOpen: false,
      isMobile: false,
    }
  },
  mounted() {
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 768;
    },
    openModal(cardId) {
      this.activeCardInfo = this.cardData[cardId];
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.activeCardInfo = null;
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};

</script>